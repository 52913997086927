export class DropdownOption {
	name: string;
	code: string;

	constructor(dropdownOption: DropdownOption) {
		Object.assign(this, dropdownOption);
	}
}

export const template: DropdownOption[] = [
	{name: 'Phase 0', code: 'Phase 0'},
	{name: 'Phase 1', code: 'Phase 1'},
	{name: 'Phase 2', code: 'Phase 2'},
	{name: 'Phase 3', code: 'Phase 3'}
];

export const answerOptions: DropdownOption[] = [
	{name: 'Design Records', code: 'Design Records'},
	{name: 'Authorized engineering change documents', code: 'Authorized engineering change documents'},
	{name: 'Customer Engineering Approved', code: 'Customer Engineering Approved'},
	{name: 'Design FMEA', code: 'Design FMEA'},
	{name: 'Process Flow', code: 'Process Flow'},
	{name: 'Process FMEA', code: 'Process FMEA'}
];

export const optionGroup: DropdownOption[] = [
	{name: 'Yes or No', code: 'YesNo'},
	{name: 'Option type 1', code: 'Option1'},
	{name: 'Option type 2', code: 'Option2'},
	{name: 'Option type 3', code: 'Option3'}
];

export const yesNoOption: DropdownOption[] = [
	{name: 'Yes', code: 'Yes'},
	{name: 'No', code: 'No'}
];

export const ppapLevel: DropdownOption[] = [
	//{name: 'Select PPAP Level', code: null},
	{name: '', code: ''},
	{name: '1', code: 'LV1'},
	{name: '3', code: 'LV3'},
	{name: '5', code: 'LV5'}
];

//ppapPhaseOption
export const ppapPhaseOption: DropdownOption[] = [
	//{name: 'Select PPAP PLAN', code: null},
	//Adding All Option in PPAP Timing
	{name: 'All', code: 'ALL'},
	{name: 'Beyond 6 Month', code: 'PBS'},
	{name: 'Next 6 Month', code: 'PNS'},
	{name: 'Parts Untimed', code: 'PTU'},
	{name: 'Past Due', code: 'PPD'}
];

export const priorityOption: DropdownOption[] = [
	{name: 'All', code: 'ALL'},
	{name: 'Priority', code: 'PR'},
	{name: 'Non Priority', code: 'NPR'}
];


export const auditLayout: DropdownOption[] = [
	{name: 'Annual', code: null},
	{name: 'Quarterly', code: null},
	{name: 'Six Months', code: null},
	{name: 'Not Applicable', code: null}
];

export const requestSubmitLevel: DropdownOption[] = [
	{name: 'Level 1 - Warrant only (and for designated appearance items, an Appearance Approval Report) submitted to customer.', code: null},
	{name: 'Level 2 - Warrant with product samples and limited supporting data submitted to customer.', code: null},
	{name: 'Level 3 - Warrant with product samples and complete supporting data submitted to customer.', code: null},
	{name: 'Level 4 - Warrant and other requirements as defined by customer.', code: null},
	{name: 'Level 5 - Warrant with product samples and complete supporting data reviewed at supplier\'s manufacturing location.', code: null}
];

export const paReqSource: DropdownOption[] = [
	{name: '', code: null},
	{name: 'Initial Tool Order', code: null},
	{name: 'WebQuote', code: null},
	{name: 'CPA (Commercial and Program Agreement)', code: null},
	{name: 'SAL (Sourcing Agreement Letter)', code: null},
	{name: 'Other (specify in detail at right)', code: null}
];

export const aftrPaReqSource: DropdownOption[] = [
	{name: '', code: null},
	{name: 'Amended or subsequent Tool Order', code: null},
	{name: 'WebQuote', code: null},
	{name: 'CPA (Commercial and Program Agreement)', code: null},
	{name: 'SAL (Sourcing Agreement Letter)', code: null},
	{name: 'Other (specify in detail at right)', code: null}
];

// export const copyToPartNumbersOption: DropdownOption[] = [
// 	{name: 'AB39 – 2122345 – AE', code: 'NY'},
// 	{name: 'AB39 – 2122346 – AE', code: 'RM'},
// 	{name: 'AB39 – 2122347 – AE', code: 'LDN'},
// 	{name: 'AB39 – 2122348 – AE', code: 'IST'},
// 	{name: 'AB39 – 2122349 – AE', code: 'PRS'}
// ];
